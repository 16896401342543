.translating-image {
  animation: move-up-down 2.8s infinite;
}

@keyframes move-up-down {
  0%,
  
  100% {
    transform: translateY(0) rotate(180deg);
  }

  25% {
    transform: translateY(0) rotate(180deg);
  }

  40% {
    transform: translateY(50px) rotate(180deg);
  }

  42% {
    transform: translateY(50px) rotate(183deg);
  }

  47% {
    transform: translateY(50px) rotate(178deg);
  }

  50% {
    transform: translateY(50px) rotate(180deg);
  }

  55% {
    transform: translateY(50px) rotate(180deg);
  }
}